import { Route, Routes, useLocation } from 'react-router-dom';
import { HomePage } from './pages/home/Home';
import './App.css';
import { AboutPage } from './pages/About';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Mouse } from './compositions/Mouse';
import { ImprintPage } from './pages/Imprint';
import { ExplorePage } from './pages/explore/Explore';
import { CollectionsPage } from './pages/collections/Collections';
import { COLLECTIONS } from './pages/collections/collections.constants';
import { PageCollection } from './pages/collections/Collection';

function App() {
  return (
    <>
      <Mouse />
      <AnimatedRoutes />
    </>
  );
}

const AnimatedRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomePage />} />
          {COLLECTIONS.map((config, index) => {
            return (
              <Route path={`/collections/${config.handle}`} element={<PageCollection collectionIndex={index} config={config} />} />
            )
          })}
          <Route path="/explore" element={<ExplorePage />} />
          <Route path="/collections" element={<CollectionsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/imprint" element={<ImprintPage />} />
        </Routes>
      </AnimatePresence>
    </>
  )
}

export default App;
