import { motion } from 'framer-motion';
import { useLocation } from "react-router-dom";
import { useRef } from 'react';
import { Section } from '../../components/Section';
import { RevealUp } from '../../components/RevealUp';
import { PageTransition } from '../../components/PageTransition';

export const HomePage = () => {
    const location = useLocation();
    const container: any = useRef()

    return (
        <PageTransition
            wrapperKey={location.pathname}
        >
            <motion.div
                ref={container}
                id={`${location.pathname}-page`}
                className="App"
            >
                <Section
                    style={{
                        backgroundColor: 'var(--color-beta)',
                        color: 'var(--color-alpha)',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '2rem',
                    }}
                >

                    <div
                        style={{
                            marginBottom: '4rem',
                        }}
                    >
                        Interactive Connective ®
                    </div>
                    <div>
                        <div
                            style={{
                                fontSize: 'clamp(1rem, 10vw, 8rem)',
                                lineHeight: 'clamp(1rem, 10vw, 8rem)',
                            }}
                        >
                            <RevealUp>
                                Delivering Messages, <br />
                                Crafting Digital Experience.
                            </RevealUp>
                        </div>
                        <div
                            style={{
                                marginTop: '2rem',
                            }}
                        >
                            (Vienna, Austria) (Full website soon)
                        </div>
                    </div>
                </Section>
            </motion.div>
        </PageTransition>

    )
}
