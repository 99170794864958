import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import { styled } from "styled-components"
import { StyledLink } from "./Link"
import { Button } from "./Button"

const Wrapper = styled(motion.div)`
    display: flex;
    padding: 1rem;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    color: var(--color-beta);
    flex-direction: column;
    gap: .5rem;
    z-index: 999;
    text-transform: uppercase;
`

const LogoWrapper = styled(motion.div)`
    display: flex;
    gap: .5rem;
    align-items: center;
    font-weight: bold;
`

const Logo = styled(motion.div)`
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-radius: 10px;
`

export const Navigation = () => {
    return (
        <Wrapper>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '1rem',
                    alignItems: 'center',
                }}
            >
                <Link to="/">
                    <LogoWrapper
                        initial="IDLE"
                        whileHover="HOVER"
                    >
                        <Logo
                            style={{
                                display: 'none'
                            }}
                            variants={{
                                'IDLE': {
                                    background: 'rgba(255, 255, 255, 0)'
                                },
                                'HOVER': {
                                    background: 'rgba(255, 255, 255, 1)'
                                }
                            }}
                        />
                        <motion.span
                            variants={{
                                'IDLE': {
                                    x: 0
                                },
                                'HOVER': {
                                    x: 5
                                }
                            }}
                        >
                            Interactive Connective ®
                        </motion.span>
                    </LogoWrapper>
                </Link>
                <div
                    style={{
                        display: 'flex',
                        gap: '2rem',
                        alignItems: 'center',
                    }}
                >
                    <Link to="/projects">
                        <StyledLink>
                            Projects
                        </StyledLink>
                    </Link>
                    <Link to="/collections">
                        <StyledLink>
                            Collections
                        </StyledLink>
                    </Link>
                    <Link to="/shop">
                        <StyledLink>
                            Shop (SOON)
                        </StyledLink>
                    </Link>
                    <Link to="/about">
                        <StyledLink>
                            About
                        </StyledLink>
                    </Link>
                    <Link to="/explore">
                        <Button>
                            Interactive Explore
                        </Button>
                    </Link>
                </div>
            </div>
        </Wrapper>
    )
}
