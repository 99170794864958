import { useLocation } from "react-router-dom";
import { motion } from 'framer-motion';
import { FC } from "react";
import { PageTransition } from "../../components/PageTransition";
import { Section } from "../../components/Section";
import { ImageRender } from "../../components/ImageRender";
import { CollectionConfig } from "./collections.constants";
import { Footer } from "../../compositions/Footer";
import { Navigation } from "../../components/Navigation";
import { RevealUp } from "../../components/RevealUp";


type Props = {
    collectionIndex: number,
    config: CollectionConfig,
}

export const PageCollection: FC<Props> = ({
    config,
}) => {
    const location = useLocation();

    return (
        <>
            <PageTransition
                wrapperKey={location.pathname}
            >
                <motion.div
                    id={`${location.pathname}-page`}
                    className="App"
                >
                    <Navigation />

                    <Section
                        style={{
                            display: 'grid',
                            gap: '1rem',
                            backgroundColor: 'var(--color-alpha)',
                            padding: '40vh 1rem 2rem 1rem'
                        }}
                    >
                        <RevealUp
                            style={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                fontSize: 'clamp(2rem, 8vw, 6rem)',
                                lineHeight: 'clamp(2rem, 8vw, 6rem)',
                            }}
                        >
                            <div>{config?.name}</div>
                        </RevealUp>

                        {config.items.map((item) => {
                            return (
                                <ImageRender
                                    aspectRatio={item.aspectRatio}
                                    images={item.images}
                                />
                            )
                        })}
                    </Section>

                    <Section
                        style={{
                            backgroundColor: 'var(--color-alpha)',
                        }}
                    >
                        <Footer />
                    </Section>
                </motion.div>
            </PageTransition>
        </>
    )
}
