import { useEffect, useMemo, useRef, useState } from "react"
import { motion } from 'framer-motion';
import { useMouseStore } from "../stores/useMouseStore";
import { mobileCheck } from "../helper/mobile.helper";

export const Mouse = () => {
    const ref: any = useRef();
    const { type } = useMouseStore();
    const [isMouseDown, setIsMouseDown] = useState(false);

    useEffect(() => {
        document.addEventListener('mousemove', (event) => {
            if (ref.current && ref.current.style) {
                ref.current.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
            }
        })

        document.addEventListener('mousedown', () => {
            setIsMouseDown(true)
        })

        document.addEventListener('mouseup', () => {
            setIsMouseDown(false)
        })
    }, [])

    const pointerDimension = useMemo(() => {
        let dimensions = { width: 0, height: 0 };

        switch (type) {
            case 'HOVER':
            case 'MUTE':
            case 'UNMUTE':
                dimensions = {
                    width: 120,
                    height: 120,
                }
                break;
            default:
                break;
        }

        if (isMouseDown) {
            dimensions = { width: dimensions.width - 5, height: dimensions.height - 5 }
        }

        return dimensions;
    }, [isMouseDown, type])

    if (mobileCheck()) return null;

    return (
        <div className="mouse-container" ref={ref}>
            <motion.div animate={pointerDimension} className="mouse-pointer">
                {type === 'HOVER' && 'OPEN'}
                {type === 'MUTE' && 'MUTE'}
                {type === 'UNMUTE' && 'UNMUTE'}
            </motion.div>
        </div>
    )
}
