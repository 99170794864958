import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import { styled } from "styled-components"
import { StyledLink } from "../components/Link"
import { RevealUp } from "../components/RevealUp"
import { Canvas } from "@react-three/fiber"
import { Box, PerspectiveCamera, Sphere } from "@react-three/drei"
import * as THREE from 'three';

const Wrapper = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
    color: var(--color-beta);
    padding: 4rem 1rem 1rem 1rem;
    text-transform: uppercase;

    .footer-item {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    @media screen and (min-width: 640px) {
        grid-template-columns: repeat(4, 1fr);
    }
`

const randomPosition = () => {
    return {
        x: Math.random() * 20 - 10,
        y: Math.random() * 20 - 10,
        z: Math.random() * 10 - 5,
    };
};

const randomShapeType = () => {
    return Math.random() < 0.5 ? 'box' : 'sphere';
};


const calculateScale = (y: any) => {
    // Skalierung zwischen 1 und 4 basierend auf Y-Koordinate
    const minScale = 1;
    const maxScale = 4;

    const minY = 0;
    const maxY = -10;

    return minScale + ((maxScale - minScale) * (y - minY)) / (maxY - minY);
};

const randomRotation = (): [number, number, number] => {
    return [Math.random() * Math.PI * 2, Math.random() * Math.PI * 2, Math.random() * Math.PI * 2];
};

const ShapeGenerator = ({ shapeType, numShapes }: any) => {
    const shapes = [];

    for (let i = 0; i < numShapes; i++) {
        const shapeType = randomShapeType();
        const position: any = randomPosition();
        const scale = calculateScale(position.y);
        const rotation = randomRotation();
        shapes.push(
            <mesh key={i} position={[position.x, position.y, position.z]} scale={scale} rotation={rotation}>
                {shapeType === 'box' ? <Box material={new THREE.MeshStandardMaterial({ color: new THREE.Color('#f0f0f0') })} /> : <Sphere material={new THREE.MeshStandardMaterial({ color: new THREE.Color('#f0f0f0') })} />}
            </mesh>
        );
    }

    return (
        <>
            {shapes}
        </>
    );
};

export const Footer = () => {
    return (
        <>
            <Wrapper>
                <RevealUp className="footer-item">
                    <Link to="/">
                        <StyledLink>Home</StyledLink>
                    </Link>
                    <Link to="/about">
                        <StyledLink>About</StyledLink>
                    </Link>
                </RevealUp>
                <RevealUp className="footer-item">
                    <Link to="https://dribbble.com/interactiveconnective" target="_blank" rel="noopener noreferrer">
                        <StyledLink>Dribbble</StyledLink>
                    </Link>
                    <Link to="https://www.instagram.com/interactiveconnective/" target="_blank" rel="noopener noreferrer">
                        <StyledLink>Instagram</StyledLink>
                    </Link>
                    <Link to="https://www.behance.net/kajetanpowolny" target="_blank" rel="noopener noreferrer">
                        <StyledLink>Behance</StyledLink>
                    </Link>
                    <Link to="https://linkedin.com/company/interactive-connective" target="_blank" rel="noopener noreferrer">
                        <StyledLink>LinkedIn</StyledLink>
                    </Link>
                </RevealUp>
                <RevealUp className="footer-item">
                    <Link to="https://brutalgeometric.studio/" target="_blank">
                        <StyledLink>Brutal Geometric</StyledLink>
                    </Link>
                </RevealUp>
                <RevealUp className="footer-item">
                    <Link to="/imprint">
                        <StyledLink>Imprint</StyledLink>
                    </Link>
                    <Link to="/data-privacy">
                        <StyledLink>Data Privacy</StyledLink>
                    </Link>
                </RevealUp>
            </Wrapper>
            <div
                style={{
                    position: 'relative',
                    display: 'none',
                }}
            >
                <div
                    style={{
                        backgroundImage: 'linear-gradient(to bottom, var(--color-alpha) , transparent)',
                        width: '100%',
                        height: '200px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        zIndex: 10,
                    }}
                >
                </div>
                <Canvas
                    style={{
                        width: '100%',
                        height: 500,
                        position: 'relative'
                    }}
                    gl={{
                        alpha: true,
                        antialias: true,
                    }}
                >
                    <PerspectiveCamera
                        makeDefault
                        position={[0, 0, 10]}
                        fov={32}
                    />

                    <pointLight
                        castShadow
                        intensity={1}
                        position={[2, 8, 16]}
                        shadow-mapSize={[2048, 2048]}
                        shadow-bias={-0.005}
                    >
                    </pointLight>

                    <ShapeGenerator numShapes={128 * 2} shapeType={'sphere'} />
                </Canvas>
            </div>
        </>
    )
}
