import { motion } from 'framer-motion';

export const PageTransition = ({ children, wrapperKey }: any) => {
    return (
        <motion.div
            key={wrapperKey}
        >
            <motion.div
                layout
                key="slide-in"
                className="slide-in"
                initial={{
                    scaleY: 0,
                }}
                animate={{
                    scaleY: 0,
                }}
                exit={{
                    scaleY: 1,
                    transition: {
                        duration: 1,
                        ease: [0.83, 0, 0.17, 1]
                    }
                }}
                transition={{
                    duration: 1,
                    ease: [0.83, 0, 0.17, 1]
                }}
            />
            <motion.div
                layout
                key="slide-out"
                className="slide-out"
                initial={{
                    scaleY: 1,
                }}
                animate={{
                    scaleY: 0,
                }}
                exit={{
                    scaleY: 0,
                    transition: {
                        duration: 1,
                        ease: [0.83, 0, 0.17, 1]
                    }
                }}
                transition={{
                    duration: 1,
                    ease: [0.83, 0, 0.17, 1]
                }}
            />
            {children}
        </motion.div>
    )
}
