import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { Navigation } from '../components/Navigation';
import { Section } from '../components/Section';
import { Footer } from '../compositions/Footer';
import { PageTransition } from '../components/PageTransition';

export const ImprintPage = () => {
    const location = useLocation();

    return (
        <PageTransition
            wrapperKey={location.pathname}
        >
            <motion.div
                key={location.pathname}
                className="App"
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                exit={{
                    opacity: 0
                }}
            >
                <Navigation />

                <Section
                    style={{
                        padding: '16rem 0 0 0',
                        backgroundColor: 'var(--color-alpha)',
                    }}
                >
                    <div
                        style={{
                            padding: '12rem 1rem 1rem 1rem',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: 'var(--color-beta)',
                            fontSize: 'clamp(1rem, 10vw, 8rem)',
                            lineHeight: 'clamp(1rem, 8vw, 6rem)',
                        }}
                    >
                        Imprint
                    </div>
                </Section>
                <Section
                    style={{
                        padding: '8rem 0',
                        background: 'var(--color-alpha)',
                    }}
                >
                    <div
                        style={{
                            padding: '1rem 1rem',
                            color: 'var(--color-beta)',
                            fontSize: 'clamp(1rem, 8vw, 4rem)',
                            lineHeight: 'clamp(1rem, 8vw, 4rem)'
                        }}
                    >

                        Interactive Connective ® is a digital experience studio with focus digital content creation and web experience.
                    </div>

                    <div
                        style={{
                            padding: '4rem 1rem',
                            color: 'var(--color-beta)',
                            fontSize: 'clamp(1rem, 8vw, 4rem)',
                            lineHeight: 'clamp(1rem, 8vw, 4rem)'
                        }}
                    >
                        A company studio run by Kajetan Powolny located at Zwinzstraße 5/1/14, 1160 Vienna, Austria. Registered under the following VAT-ID ATU76524646 and a member of the WKO. For detailed informations please write us on hello@interactiveconnective.studio
                    </div>
                </Section>
                <Section
                    style={{
                        backgroundColor: 'var(--color-alpha)',
                    }}
                >
                    <Footer />
                </Section>
            </motion.div>
        </PageTransition>
    )
}
