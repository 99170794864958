import create from 'zustand'

type MouseStates = 'DEFAULT' | 'HOVER' | 'CLICK' | 'LOADING' | 'SNAP' | 'NONE' | 'UNMUTE' | 'MUTE';

type CursorStoreType = {
  type: MouseStates,
  setType: (next: MouseStates) => void
}

export const useMouseStore = create<CursorStoreType>((set) => ({
  type: 'DEFAULT',
  setType: (next) => set(() => {
    return {
      type: next
    }
  })
}))
