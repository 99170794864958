import { motion } from 'framer-motion';
import { FC } from 'react';

export const RevealUp: FC<any> = ({
    style = {},
    className,
    children
}) => {

    return (
        <motion.div
            initial="IDLE"
            whileInView="VIEW"
            style={style}
            className={className}
            variants={{
                'IDLE': {
                    opacity: 0,
                    y: 20,
                    transition: {
                        duration: 1,
                        ease: [0.83, 0, 0.17, 1]
                    }
                },
                'VIEW': {
                    opacity: 1,
                    y: 0,
                    transition: {
                        duration: 1,
                        ease: [0.83, 0, 0.17, 1]
                    }
                }
            }}
        >
            {children}
        </motion.div>
    )
}
