import { useLocation } from "react-router-dom"
import { PageTransition } from "../../components/PageTransition"

export const ExplorePage = () => {
    const location = useLocation();
    return (
        <PageTransition
            wrapperKey={location.pathname}
        >
            <div>
                Explore
            </div>
        </PageTransition>
    )
}
