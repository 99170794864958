import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { Navigation } from '../../components/Navigation';
import { Section } from '../../components/Section';
import { Footer } from '../../compositions/Footer';
import { RevealUp } from '../../components/RevealUp';
import { PageTransition } from '../../components/PageTransition';
import styled from 'styled-components';
import { AspectRatioContainer } from '../../components/AspectRatioContainer';
import { COLLECTIONS } from './collections.constants';

export const CollectionGrid = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @media screen and (min-width: 1040px) {
        grid-template-columns: repeat(4, 1fr);
    }
`

export const CollectionsPage = () => {
    const location = useLocation();

    return (
        <PageTransition
            wrapperKey={location.pathname}
        >
            <motion.div
                key={location.pathname}
                className="App"
            >
                <Navigation />

                <Section
                    style={{
                        padding: '40vh 0 0 0',
                        backgroundColor: 'var(--color-alpha)',
                    }}
                >
                    <RevealUp
                        style={{
                            padding: '4rem 1rem 1rem 1rem',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: 'var(--color-beta)',
                            fontSize: 'clamp(2rem, 8vw, 6rem)',
                            lineHeight: 'clamp(2rem, 8vw, 6rem)',
                        }}
                    >
                        Collections
                    </RevealUp>
                </Section>
                <Section
                    style={{
                        padding: '0rem 0 4rem 0',
                        backgroundColor: 'var(--color-alpha)',
                    }}
                >
                    <RevealUp
                        style={{
                            padding: '1rem 1rem',
                            color: 'var(--color-beta)',
                            position: 'relative',
                            textTransform: 'uppercase',
                        }}
                    >
                        Explore collections with animations and 3D renderings
                    </RevealUp>
                </Section>

                <Section
                    style={{
                        backgroundColor: 'var(--color-alpha)',
                        padding: '2rem 1rem 4rem 1rem'
                    }}
                >
                    <CollectionGrid>
                        {COLLECTIONS.map((collection) => {
                            return (
                                <Link to={`/collections/${collection.handle}`}>
                                <AspectRatioContainer ratio="1/1">
                                    <video
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            left: 0,
                                            top: 0,
                                        }}
                                        autoPlay
                                        muted
                                        playsInline
                                        loop
                                        preload="auto"
                                    >
                                        <source src={collection.featured} type="video/mp4"></source>
                                    </video>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            bottom: '1rem',
                                            left: '1rem',
                                            zIndex: 1,
                                        }}
                                    >
                                        {collection.name}
                                    </div>
                                </AspectRatioContainer>
                                </Link>
                            )
                        })}
                    </CollectionGrid>
                </Section>

                <Section
                    style={{
                        backgroundColor: 'var(--color-alpha)',
                    }}
                >
                    <Footer />
                </Section>
            </motion.div>
        </PageTransition>
    )
}
