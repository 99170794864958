import { motion, useInView } from 'framer-motion';
import { FC, useRef } from 'react';
import { styled } from 'styled-components';
import { AspectRatioContainer } from './AspectRatioContainer';

type ContainerProps = {
    imageAmount: number
}
const Container = styled(motion.div) <ContainerProps>`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media (min-width: 640px) {
        grid-template-columns: ${(props => `repeat(${props.imageAmount}, 1fr)`)};
    }
`

type Props = {
    aspectRatio: string;
    images?: any[];
}

export const ImageRender: FC<Props> = ({
    aspectRatio,
    images,
}) => {
    const ref: any = useRef()
    const isInView = useInView(ref, {
        once: true,
        margin: '40px'
    })

    return (
        <Container
            ref={ref}
            imageAmount={images?.length ?? 0}
        >
            {images?.map((image, index) => {
                if (image.includes('.mp4')) {
                    return (
                        <AspectRatioContainer ratio={aspectRatio}>
                            <video
                                width="100%"
                                height="100%"
                                muted
                                autoPlay
                                loop
                                playsInline
                                style={{
                                    objectFit: 'cover',
                                }}
                            >
                                <source src={image} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </AspectRatioContainer>
                    )
                }

                return (
                    <AspectRatioContainer ratio={aspectRatio}>
                        <motion.img
                            src={image}
                            style={{
                                width: '100%',
                                height: '0%',
                                objectFit: 'cover'
                            }}
                            initial={{
                                height: '0%',
                            }}
                            animate={{
                                height: isInView ? '100%' : '0%',
                                transition: {
                                    delay: .25 + (index / 4),
                                    duration: 1,
                                    ease: [0.83, 0, 0.17, 1]
                                }
                            }}
                        />
                    </AspectRatioContainer>
                )
            })}
        </Container>
    )
}
