import { motion } from "framer-motion"
import { FC } from "react"
import styled from "styled-components"

const ButtonWrapper = styled(motion.div)`
    background-color: var(--color-beta);
    color: var(--color-alpha);
    padding: .75rem 1.25rem;
    border-radius: 100rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    overflow: hidden;

`

export const Button: FC<any> = ({
    children
}) => {
    return (
        <ButtonWrapper
            initial="IDLE"
            whileHover="HOVER"
            whileTap="TAP"
            variants={{
                'IDLE': {
                    scale: 1,
                },
                'TAP': {
                    scale: .95,
                }
            }}
        >
            <div
                style={{
                    visibility: 'hidden',
                }}
            >
                {children}
            </div>
            <motion.div
                style={{
                    position: 'absolute',
                    margin: 'auto',
                    top: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                variants={{
                    'IDLE': {
                        top: '0%'
                    },
                    'HOVER': {
                        top: '-100%'
                    }
                }}
            >
                {children}
            </motion.div>
            <motion.div
                style={{
                    position: 'absolute',
                    margin: 'auto',
                    top: '100%',
                    left: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                variants={{
                    'IDLE': {
                        top: '100%'
                    },
                    'HOVER': {
                        top: '0%'
                    }
                }}
            >
                Enter
            </motion.div>
        </ButtonWrapper>
    )
}
