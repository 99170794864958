import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { Navigation } from '../components/Navigation';
import { Section } from '../components/Section';
import { Footer } from '../compositions/Footer';
import { useRef } from 'react';
import { RevealUp } from '../components/RevealUp';
import { PageTransition } from '../components/PageTransition';

export const AboutPage = () => {
    const location = useLocation();

    const container: any = useRef()
    const tracking: any = useRef()

    return (
        <PageTransition
            wrapperKey={location.pathname}
        >
            <motion.div
                ref={container}
                id={`${location.pathname}-page`}
            >
                <Navigation />

                <div
                    ref={tracking}
                    style={{
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        height: '100%',
                        width: '400px',
                        pointerEvents: 'none',
                    }}
                >
                </div>

                <Section
                    style={{
                        padding: '40vh 0 0 0',
                        backgroundColor: 'var(--color-alpha)',
                    }}
                >
                    <div
                        style={{
                            padding: '4rem 1rem 2rem 1rem',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: 'var(--color-beta)',
                            fontSize: 'clamp(1rem, 10vw, 8rem)',
                            lineHeight: 'clamp(1rem, 8vw, 6rem)',
                        }}
                    >
                        Studio Ethos
                    </div>
                </Section>
                <Section
                    style={{
                        padding: '2rem 0 4rem 0',
                        backgroundColor: 'var(--color-alpha)',
                    }}
                >
                    <RevealUp
                        style={{
                            padding: '1rem 1rem',
                            color: 'var(--color-beta)',
                            fontSize: 'clamp(1rem, 8vw, 4rem)',
                            lineHeight: 'clamp(1rem, 8vw, 5rem)',
                            position: 'relative',
                        }}
                    >
                        Interactive Connective ® is a digital experience design studio. It is based in Vienna and focuses on digital content creation, web development and motion design to craft web experiences.
                    </RevealUp>
                </Section>

                <Section
                    style={{
                        padding: '0rem 0 2rem 0',
                        backgroundColor: 'var(--color-alpha)',
                    }}
                >
                    <RevealUp
                        style={{
                            padding: '1rem 1rem',
                            color: 'var(--color-beta)',
                            fontSize: 'clamp(1rem, 8vw, 4rem)',
                            lineHeight: 'clamp(1rem, 8vw, 5rem)',
                            position: 'relative',
                        }}
                    >
                        Companies who need us are: Organizations that want to visualize or animate their products, interiors or other content in a modern, playfull and interactive way on the web.
                    </RevealUp>
                </Section>

                <Section
                    style={{
                        padding: '0rem 0 8rem 0',
                        backgroundColor: 'var(--color-alpha)',
                    }}
                >
                    <RevealUp
                        style={{
                            padding: '1rem 1rem',
                            color: 'var(--color-beta)',
                            fontSize: 'clamp(1rem, 8vw, 4rem)',
                            lineHeight: 'clamp(1rem, 8vw, 5rem)',
                            position: 'relative',
                        }}
                    >
                        What your customers will get: Interactive content media solutions integrated into your website.
                    </RevealUp>
                </Section>

                <Section
                    style={{
                        backgroundColor: 'var(--color-alpha)',
                    }}
                >
                    <Footer />
                </Section>
            </motion.div>
        </PageTransition>
    )
}
