import { motion } from "framer-motion"
import { FC } from "react";
import styled from "styled-components"

type ContainerProps = {
    heightInPercent: number;
}
const Container = styled(motion.div)<ContainerProps>`
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: ${props => props.heightInPercent}%;
    }
`

type Props = {
    ratio?: any;
    children?: any;
}

export const AspectRatioContainer: FC<Props> = ({
    ratio = '1/1',
    children,
}) => {
    return (
        <Container
            heightInPercent={(ratio.split('/')[1] / ratio.split('/')[0]) * 100}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
            >
                {children}
            </div>
        </Container>
    )
}
