import { styled } from 'styled-components';
import { motion } from 'framer-motion';
import { FC } from 'react';

const LinkContainer = styled(motion.div)`
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    color: var(--color-beta);
    cursor: pointer;
`

const LinkActiveIcon = styled(motion.div)`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #000;
    display: inline-block;
    margin-right: var(--spacing-unit);
`

type Props = {
    children?: any;
    active?: boolean;
}

export const StyledLink: FC<Props> = ({
    children,
    active = false,
}) => {
    return (
        <LinkContainer initial={active ? 'ACTIVE' : 'REST'} whileHover="HOVER">
            <LinkActiveIcon
                variants={{
                    'ACTIVE': {
                        opacity: 1,
                        marginLeft: 0,
                    },
                    'REST': {
                        opacity: 0,
                        marginLeft: -14,
                    }
                }}
            />
            <motion.span
                variants={{
                    'HOVER': {
                        marginLeft: 8
                    }
                }}
            >
                {children}
            </motion.span>
        </LinkContainer>
    )
}
