import StephansplatzVideo from './../../assets/0000-0048.mp4';
import ImgStephansplatz from './../../assets/stephansplatz-mono.png';
import Donaukanal from './../../assets/donaukanal.png';
import U6 from './../../assets/u6.png';

import Image from './../../assets/ic-businesscard-light.png';
import ImgPanelak from './../../assets/panelak.png';

export type CollectionConfig = {
    handle: string,
    name: string,
    active: boolean,
    blurred?: boolean,
    featured?: string;
    items: {
        aspectRatio: string,
        images: any[],
    }[]
}

export const COLLECTIONS: CollectionConfig[] = [
    {
        handle: 'wiener',
        name: 'Wiener',
        active: true,
        featured: StephansplatzVideo,
        items: [
            {
                aspectRatio: '2/3',
                images: [
                    StephansplatzVideo,
                    ImgStephansplatz,
                    Donaukanal,
                    U6,
                ]
            }
        ]
    },
    {
        handle: 'random',
        name: 'Random',
        active: true,
        featured: ImgPanelak,
        items: [
            {
                aspectRatio: '2/3',
                images: [
                    Image,
                    ImgPanelak,
                ]
            }
        ]
    }
]
